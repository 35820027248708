import * as React from "react"
import styled from "styled-components/macro"

import Container from "../components/common/Container"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Wrapper = styled.div`
  padding: 100px 0;
  text-align: center;
`
const VideoIframe = styled.iframe`
  width: 100%;
  max-width: 960px;
  display: block;
  border: none;
  height: 400px;
  margin: 0 auto;
  border: 15px solid #fff;
`

const ThankYouPage = () => (
  <Layout page="home">
    <Container>
      <Wrapper>
        <h1>THANK YOU FOR CONTACTING US!</h1>
        <p>
          Keep an eye on your inbox because we are going to get back to you
          ASAP.
        </p>
        <p>
          But until then we have something for you to watch: more of our
          signature FLIP UNIT Airtrack freestyle acrobatics show!
        </p>
        <VideoIframe
          loading="eager"
          src={`https://www.youtube-nocookie.com/embed/H2z7ofpz31k`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen
        />
      </Wrapper>
    </Container>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="THANK YOU| Thanks for contacting us!"
    description="Thank you for contacting us"
    slug="/thank-you"
  />
)

export default ThankYouPage
